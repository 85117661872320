import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AdminPets from './pages/AdminPets';
import AnimalDetails from './pages/AnimalDetails';
import Statistics from './pages/Statistics';
import CampaignCalendar from './pages/CampaignCalendar';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
  },
});

const App = () => {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {location.pathname !== '/login' && <Header />}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin-pets" element={<AdminPets />} />
        <Route path="/admin-pets/:id" element={<AnimalDetails />} />
        <Route path="/statistics" element={<Statistics />} />
        <Route path="/campaign-calendar" element={<CampaignCalendar />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
