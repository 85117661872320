import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';

interface AnimalTableProps {
  pets: Array<{
    id: string;
    tipo: string;
    nombre: string;
    raza: string;
    foto: string;
    vacunas: Array<{
      fecha: string;
      dosis: number;
      aplicador: string;
      ciudad: string;
      barrio: string;
    }>;
    procedimientos: Array<{
      tipo: string;
      fecha: string;
      realizador: string;
      ciudad: string;
      barrio: string;
    }>;
  }>;
  onDetailClick: (pet: { id: string }) => void;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AnimalTable: React.FC<AnimalTableProps> = ({ pets, onDetailClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Raza</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pets.map((pet) => (
            <StyledTableRow key={pet.id}>
              <TableCell>{pet.id}</TableCell>
              <TableCell>{pet.nombre}</TableCell>
              <TableCell>{pet.tipo}</TableCell>
              <TableCell>{pet.raza}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => onDetailClick(pet)}>
                  Ver Detalles
                </Button>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnimalTable;