const cities = ['Bogotá', 'Medellín', 'Cali', 'Barranquilla', 'Cartagena', 'Bucaramanga', 'Pereira', 'Manizales', 'Cúcuta', 'Santa Marta'];
const barrios = ['Centro', 'Norte', 'Sur', 'Este', 'Oeste', 'Laureles', 'San Antonio', 'Miraflores', 'El Poblado', 'Chapinero'];
const doctors = ['Dr. Juan', 'Dr. Ana', 'Dr. Carlos', 'Dr. María', 'Dr. Pedro', 'Dr. Luisa'];
const procedures = ['Esterilización', 'Profilaxis', 'Implantación de Chip', 'Desparasitación'];
const vaccines = ['Rabia', 'Parvovirus', 'Moquillo', 'Hepatitis', 'Leptospirosis'];
const animalNames = ['Rex', 'Luna', 'Simba', 'Milo', 'Bella', 'Coco', 'Rocky', 'Max', 'Chloe', 'Daisy', 'Bailey', 'Buddy', 'Duke', 'Zoe', 'Molly', 'Lucy', 'Charlie', 'Jack', 'Toby', 'Oliver', 'Oscar', 'Sam', 'Leo', 'Milo', 'Archie', 'Loki', 'Simba', 'Misty', 'Cleo', 'Felix', 'Nala', 'Lily', 'Sophie', 'Ginger', 'Mimi', 'Pepper', 'Shadow', 'Tigger', 'Kitty', 'Oreo', 'Mittens', 'Whiskers', 'Tiger', 'Smokey', 'Fluffy', 'Simba', 'Missy', 'Princess', 'Snowball'];
const dogPhotos = [
    'https://images.unsplash.com/photo-1560807707-8cc77767d783', 
    'https://images.unsplash.com/photo-1592194996308-7d9a04d14197',
    'https://images.unsplash.com/photo-1560807707-8cc77767d785',
    'https://images.unsplash.com/photo-1517849845537-4d257902454a',
    'https://images.unsplash.com/photo-1525253086316-d0c936c814f8',
    'https://images.pexels.com/photos/58997/pexels-photo-58997.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/220938/pexels-photo-220938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/551628/pexels-photo-551628.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/1490908/pexels-photo-1490908.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/128817/pexels-photo-128817.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/97082/weimaraner-puppy-dog-snout-97082.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/2623968/pexels-photo-2623968.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/895259/pexels-photo-895259.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/247937/pexels-photo-247937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/1938126/pexels-photo-1938126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/1189673/pexels-photo-1189673.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/612813/pexels-photo-612813.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
  ];

const catPhotos = [
    'https://images.unsplash.com/photo-1574158622682-e40e69881006',
     'https://images.pexels.com/photos/45201/kitty-cat-kitten-pet-45201.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/104827/cat-pet-animal-domestic-104827.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/416160/pexels-photo-416160.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/1170986/pexels-photo-1170986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/208984/pexels-photo-208984.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/1543793/pexels-photo-1543793.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/127028/pexels-photo-127028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/96938/pexels-photo-96938.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/821736/pexels-photo-821736.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/991831/pexels-photo-991831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/615369/pexels-photo-615369.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/691583/pexels-photo-691583.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/736532/pexels-photo-736532.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/1404819/pexels-photo-1404819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
     'https://images.pexels.com/photos/3777622/pexels-photo-3777622.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
]

const generateRandomDate = () => {
  const start = new Date(2020, 0, 1);
  const end = new Date();
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const generateRandomPet = (id: number) => {
    const tipo = Math.random() > 0.5 ? 'Perro' : 'Gato';
    const nombre = animalNames[id % animalNames.length];
    const raza = tipo === 'Perro' ? 'Labrador' : 'Siamés';
    const ciudad = cities[Math.floor(Math.random() * cities.length)];
    const foto = tipo === 'Perro' ? dogPhotos[id % dogPhotos.length] : catPhotos[id % catPhotos.length];
  

  const vacunas = Array.from({ length: Math.floor(Math.random() * 5) + 1 }, () => {
    const fecha = generateRandomDate();
    return {
      nombre: vaccines[Math.floor(Math.random() * vaccines.length)],
      fecha: fecha.toISOString().split('T')[0],
      dosis: Math.floor(Math.random() * 3) + 1,
      aplicador: doctors[Math.floor(Math.random() * doctors.length)],
      ciudad,
      barrio: barrios[Math.floor(Math.random() * barrios.length)],
    };
  });

  const procedimientos = Array.from({ length: Math.floor(Math.random() * 5) + 1 }, () => {
    const fecha = generateRandomDate();
    return {
      tipo: procedures[Math.floor(Math.random() * procedures.length)],
      fecha: fecha.toISOString().split('T')[0],
      realizador: doctors[Math.floor(Math.random() * doctors.length)],
      ciudad,
      barrio: barrios[Math.floor(Math.random() * barrios.length)],
    };
  });

  return {
    id: id.toString(),
    tipo,
    nombre,
    raza,
    foto,
    vacunas,
    procedimientos,
  };
};

export const mockPets = Array.from({ length: 50 }, (_, i) => generateRandomPet(i + 1));
