import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { mockPets } from '../mockData';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AnimalDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const pet = mockPets.find((p) => p.id === id);

  if (!pet) {
    return <Typography variant="h6">Animal no encontrado</Typography>;
  }

  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={pet.foto} alt={pet.nombre} sx={{ width: 100, height: 100, marginRight: 2 }} />
        <Box>
          <Typography variant="h4" gutterBottom>{pet.nombre}</Typography>
          <Typography gutterBottom>{pet.tipo}</Typography>
          <Typography gutterBottom>{pet.raza}</Typography>
        </Box>
      </Box>

      <Typography variant="h5" gutterBottom>
        Vacunas
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Dosis</TableCell>
              <TableCell>Aplicador</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Barrio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pet.vacunas.map((vacuna, index) => (
              <StyledTableRow key={index}>
                <TableCell>{vacuna.nombre}</TableCell>
                <TableCell>{vacuna.fecha}</TableCell>
                <TableCell>{vacuna.dosis}</TableCell>
                <TableCell>{vacuna.aplicador}</TableCell>
                <TableCell>{vacuna.ciudad}</TableCell>
                <TableCell>{vacuna.barrio}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" gutterBottom>
        Procedimientos
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Realizador</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Barrio</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pet.procedimientos.map((proc, index) => (
              <StyledTableRow key={index}>
                <TableCell>{proc.tipo}</TableCell>
                <TableCell>{proc.fecha}</TableCell>
                <TableCell>{proc.realizador}</TableCell>
                <TableCell>{proc.ciudad}</TableCell>
                <TableCell>{proc.barrio}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AnimalDetails;
