import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Box, Typography, Button } from '@mui/material';
import AnimalTable from '../components/AnimalTable';
import { mockPets } from '../mockData';

const AdminPets: React.FC = () => {
  const [search, setSearch] = useState('');
  const [pets, setPets] = useState(mockPets);
  const navigate = useNavigate();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleAddPet = () => {
    // Lógica para agregar un nuevo peludito (puedes implementar un formulario modal aquí)
    const newPet = {
      id: (pets.length + 1).toString(),
      tipo: 'Gato',
      nombre: 'Nuevo Peludito',
      raza: 'Siamés',
      foto: 'https://via.placeholder.com/150',
      vacunas: [],
      procedimientos: [],
    };
    setPets([...pets, newPet]);
  };

  const handleDetailClick = (pet: { id: string }) => {
    navigate(`/admin-pets/${pet.id}`);
  };

  const filteredPets = pets.filter((pet) =>
    pet.nombre.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Administrar Peluditos
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <TextField
          label="Buscar"
          value={search}
          onChange={handleSearchChange}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleAddPet}>
          Agregar Peludito
        </Button>
      </Box>
      <AnimalTable pets={filteredPets} onDetailClick={handleDetailClick} />
    </Container>
  );
};

export default AdminPets;