import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Card, CardContent } from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExportIcon from '@mui/icons-material/DataArray';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Opciones:
      </Typography>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        <Card onClick={() => navigate('/admin-pets')} style={{ margin: 16, cursor: 'pointer' }}>
          <CardContent>
            <PetsIcon />
            <Typography variant="h6">Administrar Peluditos</Typography>
          </CardContent>
        </Card>
        <Card onClick={() => navigate('/statistics')} style={{ margin: 16, cursor: 'pointer' }}>
          <CardContent>
            <AssessmentIcon />
            <Typography variant="h6">Estadísticas</Typography>
          </CardContent>
        </Card>
        <Card onClick={() => navigate('/campaign-calendar')} style={{ margin: 16, cursor: 'pointer' }}>
          <CardContent>
            <CalendarTodayIcon />
            <Typography variant="h6">Calendario de Campañas</Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Dashboard;
