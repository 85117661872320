import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { mockPets } from '../mockData';

type ProcedureType = 'Esterilización' | 'Profilaxis' | 'Implantación de Chip' | 'Desparasitación';
type AnimalType = 'Perro' | 'Gato';

interface ProcedureData {
  Esterilización: number;
  Profilaxis: number;
  'Implantación de Chip': number;
  Desparasitación: number;
}

interface VaccineData {
  [key: string]: number; // Para manejar diferentes nombres de vacunas
}

const aggregateData = () => {
  const sterilizationsByCity: { [key: string]: { Perro: number, Gato: number } } = {};
  const proceduresByRegion: { [key: string]: ProcedureData } = {};
  const vaccinesByRegion: { [key: string]: VaccineData } = {};

  mockPets.forEach(pet => {
    pet.vacunas.forEach(vaccine => {
      if (!vaccinesByRegion[vaccine.ciudad]) {
        vaccinesByRegion[vaccine.ciudad] = {};
      }
      if (!vaccinesByRegion[vaccine.ciudad][vaccine.nombre]) {
        vaccinesByRegion[vaccine.ciudad][vaccine.nombre] = 0;
      }
      vaccinesByRegion[vaccine.ciudad][vaccine.nombre] += 1;
    });

    pet.procedimientos.forEach(proc => {
      if (proc.tipo === 'Esterilización') {
        if (!sterilizationsByCity[proc.ciudad]) {
          sterilizationsByCity[proc.ciudad] = { Perro: 0, Gato: 0 };
        }
        sterilizationsByCity[proc.ciudad][pet.tipo as AnimalType] += 1;
      }

      if (!proceduresByRegion[proc.ciudad]) {
        proceduresByRegion[proc.ciudad] = {
          Esterilización: 0,
          Profilaxis: 0,
          'Implantación de Chip': 0,
          Desparasitación: 0,
        };
      }
      proceduresByRegion[proc.ciudad][proc.tipo as ProcedureType] += 1;
    });
  });

  return { sterilizationsByCity, proceduresByRegion, vaccinesByRegion };
};

const Statistics: React.FC = () => {
  const { sterilizationsByCity, proceduresByRegion, vaccinesByRegion } = aggregateData();

  const sterilizationsData = Object.keys(sterilizationsByCity).map(city => ({
    city,
    Perro: sterilizationsByCity[city].Perro,
    Gato: sterilizationsByCity[city].Gato,
  }));

  const proceduresData = Object.keys(proceduresByRegion).map(region => ({
    region,
    Esterilización: proceduresByRegion[region].Esterilización,
    Profilaxis: proceduresByRegion[region].Profilaxis,
    'Implantación de Chip': proceduresByRegion[region]['Implantación de Chip'],
    Desparasitación: proceduresByRegion[region].Desparasitación,
  }));

  const vaccinesRegionData = Object.keys(vaccinesByRegion).map(region => {
    const data: { [key: string]: any } = { region };
    Object.keys(vaccinesByRegion[region]).forEach(vaccineName => {
      data[vaccineName] = vaccinesByRegion[region][vaccineName];
    });
    return data;
  });

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Estadísticas
      </Typography>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Esterilización por Ciudad (Perros y Gatos)
        </Typography>
        <BarChart width={600} height={300} data={sterilizationsData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="city" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Perro" fill="#8884d8" />
          <Bar dataKey="Gato" fill="#82ca9d" />
        </BarChart>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Procedimientos por Región
        </Typography>
        <BarChart width={600} height={300} data={proceduresData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="region" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Esterilización" fill="#ff8042" />
          <Bar dataKey="Profilaxis" fill="#00C49F" />
          <Bar dataKey="Implantación de Chip" fill="#FFBB28" />
          <Bar dataKey="Desparasitación" fill="#FF8042" />
        </BarChart>
      </Box>
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Vacunas por Región
        </Typography>
        <BarChart width={600} height={300} data={vaccinesRegionData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="region" />
          <YAxis />
          <Tooltip />
          <Legend />
          {Object.keys(vaccinesRegionData[0]).filter(key => key !== 'region').map((vaccineName, index) => (
            <Bar key={index} dataKey={vaccineName} fill={COLORS[index % COLORS.length]} />
          ))}
        </BarChart>
      </Box>
    </Container>
  );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default Statistics;
