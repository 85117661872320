import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { startOfWeek } from 'date-fns';
import { getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Container, Typography, Box, Button } from '@mui/material';
import Modal from 'react-modal';
import 'react-big-calendar/lib/css/react-big-calendar.css';


const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format: (date: Date, formatStr: string, options?: any) => format(date, formatStr, options),
  parse: (dateStr: string, formatStr: string, backupDate: Date) => parse(dateStr, formatStr, backupDate),
  startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn: 1 }),
  getDay,
  locales,
});

const events = [
  {
    title: 'Campaña de Vacunación - Barrio Centro',
    start: new Date(2024, 4, 22, 10, 0),
    end: new Date(2024, 4, 22, 14, 0),
    address: 'Calle Falsa 123, Barrio Centro, Bogotá',
  },
  {
    title: 'Campaña de Esterilización - Barrio Norte',
    start: new Date(2024, 4, 23, 9, 0),
    end: new Date(2024, 4, 23, 13, 0),
    address: 'Avenida Siempre Viva 456, Barrio Norte, Medellín',
  },
  {
    title: 'Campaña de Vacunación - Barrio Sur',
    start: new Date(2024, 4, 29, 11, 0),
    end: new Date(2024, 4, 29, 15, 0),
    address: 'Calle Falsa 789, Barrio Sur, Cali',
  },
  {
    title: 'Campaña de Esterilización - Barrio Este',
    start: new Date(2024, 5, 5, 10, 0),
    end: new Date(2024, 5, 5, 14, 0),
    address: 'Carrera Falsa 123, Barrio Este, Barranquilla',
  },
];

interface Event {
  title: string;
  start: Date;
  end: Date;
  address: string;
}

const CampaignCalendar: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const handleSelectEvent = (event: Event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Calendario de Campañas
      </Typography>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={handleSelectEvent}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Detalles de la Campaña"
        ariaHideApp={false}
      >
        {selectedEvent && (
          <Box>
            <Typography variant="h6">{selectedEvent.title}</Typography>
            <Typography>Fecha: {format(selectedEvent.start, 'dd/MM/yyyy')}</Typography>
            <Typography>Hora: {format(selectedEvent.start, 'HH:mm')} - {format(selectedEvent.end, 'HH:mm')}</Typography>
            <Typography>Dirección: {selectedEvent.address}</Typography>
            <Button onClick={closeModal}>Cerrar</Button>
          </Box>
        )}
      </Modal>
    </Container>
  );
};

export default CampaignCalendar;
